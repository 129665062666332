import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';


import { RESTService } from '../../services/rest.service';

// Models
import { Users } from '../../models/users.model';
import {Polygon} from "../../models/polygon.model";

@Injectable()
export class UsersService extends RESTService<Users> {

    private tokenPayload: any;
    private currentUserId: number;

    constructor(http: HttpClient, authService: NbAuthService) {
        super(http, authService);
        this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
                const tokenPayload = token.getPayload();
                this.currentUserId = tokenPayload.id;
            }
        });
    }

    // GET /Users

    public getAllUsers(
        CompanyId: any
    ): Promise<Users[]> {

        this.setApiEndpoint(
            '/companies/' +
            CompanyId +
            '/users'
        );
        return this.getAll();
    }

    // Asignar compañia
    public setUserSucursal(
        UserId: any,
    ): Promise<Users[]> {
        this.setApiEndpoint(
            '/users/' +
            UserId +
            '/sucursal'
        );
        return this.getAll();
    }

    // POST /Users
    public createUser(
        data: any,
    ): Promise<Users> {
        this.setApiEndpoint(
            '/users'
        );
        return this.save(data);
    }

    // Asignar compañia
    public setCompanyUser(
        CompanyId: any,
        UserId: any,
        data: any,
    ): Promise<Users> {
        this.setApiEndpoint(
            '/companies/' +
            CompanyId +
            '/users/' +
            UserId
        );
        return this.save(data);
    }

    // DELETE /User

    public deleteUser(
        UserId: any,
    ): Promise<boolean> {
        this.setApiEndpoint(
            '/users'
        );
        return this.delete(UserId);
    }

    public newResource(data: any): Users {
        return new Users(this, data);
    }
}
