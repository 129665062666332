import {Component, OnInit} from '@angular/core';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {Router} from '@angular/router';

import {UserService} from '../@core/data/user.service';
import {User} from '../models/user.model';

@Component({
  selector: 'ngx-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  constructor(
    private authService: NbAuthService,
    private userService: UserService,
    private router: Router,
  ) {
    const url = window.location.host;
    const subdomain = url.split('.')[0];
    this.userService._currentUser.subscribe((user: User) => {
      if (user && user.isAdmin) {
        this.router.navigate(['admin']);
      } else if (user && user.Companies && user.Companies[0] && user.Companies[0].Companyuser.ismanager === true) {
        this.router.navigate(['manager'])
      } else if (user && subdomain === 'gam') {
        this.router.navigate(['user', 'gam', 'dashboard'])
      } else if (user && !user.isAdmin) {
        this.router.navigate(['user']);
      } else {
        this.router.navigate(['']);
      }
    });
  }

  ngOnInit() {}
}
