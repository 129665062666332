import { RESTModel } from './rest.model';
import { RESTService } from '../services/rest.service';

export class Catstatus extends RESTModel {
    public id: number;
    public statusCheck: any;
    public createdAt: any;
    public updateAt: any;

    constructor(service: RESTService<Catstatus>, data: any) {
        super(service, data);
    }
}