import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';


import { RESTService } from '../../services/rest.service';

// Models
import { Group } from '../../models/group.model';

@Injectable()
export class GroupPolygonService extends RESTService<Group> {

    private tokenPayload: any;
    private currentUserId: number;

    constructor(http: HttpClient, authService: NbAuthService) {
        super(http, authService);
        this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
              const tokenPayload = token.getPayload();
              this.currentUserId = tokenPayload.id;
            }
          });
    }

    // GET /Groups

    public getAllGroups(
        UserId: any
    ): Promise<Group[]> {
        this.setApiEndpoint(
            '/users/' +
            UserId +
            '/groups'
        );
        return this.getAll();
    }

    // GET /Groups and polygons

    public getAllGroupsPolygon(
        UserId: any
    ): Promise<Group[]> {
        this.setApiEndpoint(
            '/users/' +
            UserId +
            '/groupsall'
        );
        return this.getAll();
    }

    // POST /Groups

    public setGroup(data: any): Promise<Group> {
        this.setApiEndpoint(
            '/groups'
        );
        return this.save(data);
    }

    // PUT /Groups

    public editGroup(data: any): Promise<Group> {
        this.setApiEndpoint(
            '/groups'
        );
        return this.update(data);
    }

    // DELETE /Groups

    public deleteGroup(
        GroupId: any,
    ): Promise<boolean> {

        this.setApiEndpoint(
            '/groups'
        );
        return this.delete(GroupId);
    }


    public newResource(data: any): Group {
        return new Group(this, data);
    }
}
