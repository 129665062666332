import {HttpClient} from '@angular/common/http';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { API_SERVER } from './constants.config';
import { NbPasswordAuthStrategy, NbAuthJWTToken } from '@nebular/auth';

export const AUTH_CONFIG_API = {
  name: 'email',
  baseEndpoint: API_SERVER.protocol + '://' + API_SERVER.ip + API_SERVER.startpoint,
  login: {
    alwaysFail: false,
    rememberMe: true,
    endpoint: '/users/auth',
    method: 'post',
    requireValidToken: false,
    redirect: {
      success: '/redirect',
      failure: null,
    },
    defaultErrors: ['Email o contraseña son incorrectos, inténtalo de nuevo.'],
    defaultMessages: ['Has ingresado!'],
  },
  logout: {
    alwaysFail: true,
    endpoint: '/users/logout',
    method: 'get',
    redirect: {
      success: 'auth',
      failure: '/',
    },
    defaultErrors: ['Algo salió mal, inténtalo de nuevo.'],
    defaultMessages: ['Has salido.'],
  },
  requestPass: {
    endpoint: '/users/request-pass',
    method: 'post',
    redirect: {
      success: '/',
      failure: null,
    },
    defaultErrors: ['Algo salió mal, inténtalo de nuevo.'],
    defaultMessages: ['Las instrucciones para recuperar tu contraseña han sido enviadas a tu correo.'],
  },
  resetPass: {
    endpoint: '/users/reset-pass',
    method: 'post',
    redirect: {
      success: '/',
      failure: null,
    },
    resetPasswordTokenKey: 'reset_password_token',
    defaultErrors: ['Algo salió mal, inténtalo de nuevo.'],
    defaultMessages: ['Tu contraseña se ha cambiado exitosamente.'],
  },
};

export const AUTH_CONFIG = {
  strategies: [
    NbPasswordAuthStrategy.setup({
      name: "email",
      token: {
        class: NbAuthJWTToken,
        key: 'token',
      },
      ...AUTH_CONFIG_API
    })
  ],
  forms: {
    login: {
      redirectDelay: 0,
      strategy: 'email',
      showMessages: {
        success: true,
        error: true,
      },
    },
    logout: {
      redirectDelay: 0,
      strategy: 'email',
      showMessages: {
        success: true,
        error: true,
      },
    },
    requestPassword: {
      redirectDelay: 0,
      strategy: 'email',
      showMessages: {
        success: true,
        error: true,
      },
    },
    resetPassword: {
      redirectDelay: 0,
      strategy: 'email',
      showMessages: {
        success: true,
        error: true,
      },
    },
    validation: {
      password: {
        required: true,
      },
      email: {
        required: true,
      },
    },
  },
}
