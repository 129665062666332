import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';

import { shareReplay, map, switchMap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import 'async';

// Models
import {RESTModel} from '../models/rest.model';

// Other
import {API_SERVER} from '../configs/constants.config';

export enum Verbs {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE'
}

@Injectable()
export abstract class RESTService<T extends RESTModel> {
  private accessToken: string;
  protected apiURL: string;
  protected authToken: string;
  private order: string = 'latestStudy';
  private pageLimit: number;


  constructor(
    public http: HttpClient,
    public authService: NbAuthService) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token) {
        this.authToken = token.getValue();
      }
    });
  }

  private getHttpOptions() {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.authToken,
    });
    const options = {headers: headers};
    return options;
  }


  public setApiEndpoint(apiEndpoint: string): void {
    this.apiURL =
      API_SERVER.protocol +
      '://' +
      API_SERVER.ip +
      API_SERVER.startpoint +
      apiEndpoint;
  }

  private handleError(error: any): Error {
    return new Error(error);
  }

  public save(object: T): Promise<T> {
    if (object['service']) {
      delete object['service'];
    }
    return new Promise((resolve, reject) => {
      if (object.id) {
        this.update(object)
          .then(result => {
            resolve(result);
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        this.create(object)
          .then(result => {
            if (result.id) {
              object.id = result.id;
            }
            resolve(result);
          })
          .catch((error) => {
            reject(error)
          })
      }
    });
  }

  public create(data: any): Promise<T> {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL, data, this.getHttpOptions()).subscribe(
        result => {
          console.log(result);
          resolve(this.newResource(result));
        },
        error => {
          this.handleError(error);
          reject(error);
        },
      );
    });
  }

  public getAllPaginator(): Promise<T[]> {
    return new Promise(resolve => {
      const url: string = this.apiURL;
      this.http.get(url, this.getHttpOptions()).subscribe(
        result => {
          const body: any = result;
          resolve(body);
        },
        error => {
          this.handleError(error);
          resolve(null);
        },
      );
    });
  }


  public getAll(): Promise<T[]> {
    return new Promise(resolve => {
      let url: string = this.apiURL;
      this.http.get(url, this.getHttpOptions()).subscribe(
        result => {
          const body: any = result;
          resolve(this.newResources(body));
        },
        error => {
          this.handleError(error);
          resolve(null);
        },
      );
    });
  }
  filter
  public getFull(order=this.order, page = 0, size=50, sort='desc', filter, array = []): Promise<T> {
    this.order = order;
    this.filter = filter;
    console.log(' se ejecuto getFull() con los parmetros ',this.order,order, sort, page, size, this.filter,)
    return new Promise(resolve => {
      const url: string = this.apiURL;
      let tempUrl = url
      if (!filter) { tempUrl += `&page=${page}&size=${size}&orderName=${order}&orderBy=${sort}`}
      else if (filter) {
        tempUrl += `&page=${page}&size=${size}&orderName=${order}&orderBy=${sort}&searchColumn=${this.order}&search=${this.filter}`}
      else {tempUrl += `&page=${page}&size=${size}`}
      // let tempUrl = url + (order ? `&page=${page}&size=${size}&orderName=${order}&orderBy=${sort}` : `&page=${page}&size=${size}`)
      this.http.get(tempUrl, this.getHttpOptions()).subscribe(
        result => {
            resolve(result as T);
        },
        error => {
          this.handleError(error);
          resolve(null);
        },
      );
    });
  }

  public getOne(): Promise<T> {
    const url: string = this.apiURL;
    return new Promise((resolve, reject) => this.http.get(url,  this.getHttpOptions())
        .subscribe(
          result => {
            resolve(this.newResource(result));
          },
          error => {
            this.handleError(error);
            resolve(null);
          },
        ));
  }

  public getLast(): Promise<T> {
    return new Promise(resolve => {
      const url: string = this.apiURL;
      this.http.get(url,  this.getHttpOptions()).pipe(
        map(res => res))
        .subscribe(
          result => {
            resolve(this.newResource(result));
          },
        error => {
          this.handleError(error);
          resolve(null);
        },
      );
    });
  }

  public get(id: number, alldata = false): Promise<T> {
    return new Promise(resolve => {
      let url: string = this.apiURL + '/' + id;
      if (alldata) url += '?alldata=true';
      this.http
        .get(url, this.getHttpOptions()).pipe(
        map(res => res))
        .subscribe(
          (result) => {
            resolve(this.newResource(result));
          },
          error => {
            this.handleError(error);
            resolve(null);
          },
        );
    });
  }

  protected update(object: any): Promise<any> {
    let data = {...object};
    delete data.id;
    if (data.json && data) {
      data = data;
    }
    return new Promise((resolve, reject) => {
      this.http
        .put(this.apiURL + '/' + object.id, data, this.getHttpOptions())
        .subscribe(
          (result) => {
            resolve(result);
          },
          error => {
            // this.handleError(error);
            reject(error);
          },
        );
    });
  }

  protected patch(object: any): Promise<any> {
    let data = {...object};
    delete data.id;
    if (data.json && data) {
      data = data;
    }
    return new Promise((resolve, reject) => {
      this.http
        .patch(this.apiURL, data, this.getHttpOptions())
        .subscribe(
          (result) => {
            resolve(result);
          },
          error => {
            // this.handleError(error);
            reject(error);
          },
        );
    });
  }

  public delete(id: number): Promise<boolean> {
    // let customUrl = API_SERVER.protocol + '://' + API_SERVER.ip + API_SERVER.startpoint + '/vectors'
    return new Promise(resolve => {
      this.http.delete((this.apiURL) + '/' + id, this.getHttpOptions()).subscribe(
        () => {
          resolve(true);
        },
        error => {
        this.handleError(error);
          resolve(false);
        },
      );
    });
  }

  protected abstract newResource(data: any): T;

  private newResources(data: Array<any>): Array<T> {
    try{
      return data.map(element => {
        return this.newResource(element);
      });
    } catch (e) {
      return [];
    }
  }
}
