import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';


import { RESTService } from '../../services/rest.service';

// Models
import { Catstatus } from '../../models/catstatus';

@Injectable()
export class CatStatusCheckService extends RESTService<Catstatus> {

    private tokenPayload: any;
    private currentUserId: number;

    constructor(http: HttpClient, authService: NbAuthService) {
        super(http, authService);
        this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
              const tokenPayload = token.getPayload();
              this.currentUserId = tokenPayload.id;
            }
          });
    }

    // GET ONE /cat
    public GetCatStatus(id: number): Promise<any> {
        this.setApiEndpoint(
            '/catStatusChecks'
        );
        return this.get(id);
    }


    public newResource(data: any): Catstatus {
        return new Catstatus(this, data);
    }
}
