import { RESTModel } from './rest.model';
import { RESTService } from '../services/rest.service';

export class Polygon extends RESTModel {
    public id: number;
    public geometry: any;
    public type: string;
    public userid: number;
    public groupid: number;
    public name: string;
    public noteid: number;

    constructor(service: RESTService<Polygon>, data: any) {
        super(service, data);
    }
}