import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment/moment';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/user.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { StateService } from '../../../@core/data/state.service';

import { User } from '../../../models/user.model';
import { from } from 'rxjs';
import { LogoService } from '../../../@core/services/logo.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  public client = window.location.host.split('.')[0];
  public dev = isDevMode()

  @Input() position = 'normal';
  @Input() toggler;

  user: User;

  userMenu: any;
  is_manager = true;
  ver_logos = false;

  currentLang = 'es';
  logo: string;

  constructor(
    private sidebarService: NbSidebarService,
    private userService: UserService,
    public router: Router,
    protected translate: TranslateService,
    private http: HttpClient,
    public stateService: StateService,
    private logoService: LogoService,
  ) {
    if(window.location.pathname === '/dev/') {
      this.dev = true;
    }else{
      this.dev = false;
    }
    if (this.translate.currentLang === 'es' || typeof this.translate.currentLang === 'undefined') {
      moment.locale('es');
      this.userMenu = [{
        title: this.translate.instant('general.ENGLISH'),
      }, {
        title: this.translate.instant('general.LOGOUT'),
        link: '/auth/logout',
      }];
    } else {
      moment.locale('en');
      this.userMenu = [{
        title: this.translate.instant('general.SPANISH'),
      }, {
        title: this.translate.instant('general.LOGOUT'),
        link: '/auth/logout',
      }];
    }

    this.translate.onLangChange.subscribe((event: any) => {
      this.currentLang = event.lang;
      if (event.lang === 'es') {
        moment.locale('es');
        this.userMenu = [{
          title: this.translate.instant('general.ENGLISH'),
        }, {
          title: this.translate.instant('general.LOGOUT'),
          link: '/auth/logout',
        }];
      } else {
        moment.locale('en');
        this.userMenu = [{
          title: this.translate.instant('general.SPANISH'),
        }, {
          title: this.translate.instant('general.LOGOUT'),
          link: '/auth/logout',
        }];
      }
    });

    this.logoService.getLogo().subscribe(logo => {
      this.logo = logo;
    });
  }

  onMenuClick(event: any) {
    if (event.title === this.translate.instant('general.SPANISH')) {
      this.changeLanguage('es');
    } else if (event.title === this.translate.instant('general.ENGLISH')) {
      this.changeLanguage('en');
    }
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    
    if (this.user.Companies && this.user.Companies[0] && this.user.Companies[0].Companyuser.ismanager === true) {
      this.is_manager = true;
    } else {
      this.is_manager = false
    }

  }

  changeLanguage(lang: string): void {
    this.translate.use(lang);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    if (this.user.isAdmin) {
      this.router.navigate(['admin']);
    } else if (this.user.Companies) {
      this.router.navigate(['manager'])
    } else {
      this.router.navigate(['user']);
    }
  }

  logOut() {
    this.router.navigate(['/auth/logout'])
  }

  toggleSinecta() {
    this.stateService.selectedView = !this.stateService.selectedView;
    this.stateService.mapHidden = this.stateService.selectedView && !this.stateService.mapHidden;
  }
}
