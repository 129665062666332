import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';


import { RESTService } from '../../services/rest.service';

// Models
import { Polygon } from '../../models/polygon.model';

@Injectable()
export class PolygonService extends RESTService<Polygon> {

    private tokenPayload: any;
    private currentUserId: number;

    constructor(http: HttpClient, authService: NbAuthService) {
        super(http, authService);
        this.authService.onTokenChange()
        .subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
            const tokenPayload = token.getPayload();
            this.currentUserId = tokenPayload.id;
            }
        });
    }

    // GET /poligono

    public getAllPolygon(
    userId = this.currentUserId,
    ): Promise<Polygon[]> {

        this.setApiEndpoint(
            '/users/' +
            userId +
            '/measurements'
        );
        return this.getAll();
    }

    // GET /poligono by group

    public getAllPolygonByGroup(
    groupId: any,
    ): Promise<Polygon[]> {

        this.setApiEndpoint(
            '/groups/' +
            groupId +
            '/measurements'
        );
        return this.getAll();
    }

    // POST /poligono
    public createPolygon(
        data: any
    ): Promise<Polygon> {

        this.setApiEndpoint(
            '/measurements'
        );

        return this.save(data);
    }

    // PUT /poligono
    public editPolygon(
        data: any
    ): Promise<Polygon> {
        this.setApiEndpoint(
            '/measurements'
        );
        return this.update(data);
    }

    // DELETE /poligono
    public deletePolygon(IdPolygon: number): Promise<boolean> {
        this.setApiEndpoint(
            '/measurements'
        );
        return this.delete(IdPolygon);
    }

    // GET ONE /poligono
    public GetPolygon(IdPolygon: number): Promise<any> {
        this.setApiEndpoint(
            '/measurements'
        );
        return this.get(IdPolygon, true);
    }


    public newResource(data: any): Polygon {
        return new Polygon(this, data);
    }
}
