import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

import { UserService } from '../app/@core/data/user.service';
import { User } from '../app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class ManagerRouteGuard implements CanActivate, CanActivateChild {

  private _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isManager: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private authService: NbAuthService,
    private userService: UserService,
    private router: Router,
  ) {
    this.userService._currentUser
    .subscribe((user: User) => {
      if (user) {
        this._isAuthenticated.next(true);
        this._isManager.next(user.Companies && user.Companies[0] && user.Companies[0].Companyuser.ismanager === true);
      } else {
        this._isAuthenticated.next(false);
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    if (this._isAuthenticated.getValue() && this._isManager.getValue()) {
      return true;
      
    } else {
      this.router.navigate(['redirect']);
      return false;
    }
  }

  canActivateChild() {
    if (this._isAuthenticated.getValue() && this._isManager.getValue()) {
      return true;
    } else {
      this.router.navigate(['redirect']);
      return false;
    }
  }
}
