import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import {Injectable} from '@angular/core';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {Observable, BehaviorSubject} from 'rxjs';

import {UserService} from '../app/@core/data/user.service';
import {User} from '../app/models/user.model';

@Injectable()
export class UserRouteGuard implements CanActivate, CanActivateChild {
  private _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );
  private _isAdmin: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _isActive: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private authService: NbAuthService,
    private userService: UserService,
    private router: Router,
  ) {
    this.userService._currentUser.subscribe((user: User) => {
      if (user) {
        this._isAuthenticated.next(true);
        this._isActive.next(user.isActive);
        this._isAdmin.next(user.isAdmin);
      } else {
        this._isAuthenticated.next(false);
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this._isAuthenticated.getValue() && !this._isAdmin.getValue()) {
      return true;
    } else {
      this.router.navigate(['redirect']);
      return false;
    }
  }

  canActivateChild() {
    if (!this._isActive.getValue()) {
      this.router.navigate(['user', 'deactivated']);
      return false;
    }
    return true;
  }
}
