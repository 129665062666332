import { RESTService } from '../services/rest.service';

export abstract class RESTModel {
  public id: any;
  private service: RESTService<RESTModel>;

  constructor(service?: RESTService<RESTModel>, data?: any) {
    this.service = service;
    Object.assign(this, data);
  }

  public reload(): Promise<boolean> {
    return new Promise(resolve => {
      this.service.get(this.id).then(data => {
        Object.assign(this, data);
        resolve(true);
      });
    });
  }

  public save(): Promise<boolean> {
    return new Promise(resolve => {
      this.service.save(this).then(result => {
        resolve(result !== null);
      });
    });
  }

  public delete(): Promise<boolean> {
    return this.service.delete(this.id);
  }

  public json(): string {
    return JSON.stringify(this.getAttributes());
  }

  public getAttributes(): any {
    const result: any = {};
    for (const attr of Object.keys(this)) {
      const value: any = (<any>this)[attr];
      if (this.shouldSerializeAttribute(attr, value)) {
        result[attr] = value;
      }
    }

    return result;
  }

  protected shouldSerializeAttribute(attr: any, value: any): any {
    return !(attr === 'service' || typeof value === 'function');
  }
}
