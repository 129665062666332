import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';

import { shareReplay, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import 'async';

// Models
import { RESTModel } from '../models/rest.model';

// Other
import { API_SERVER } from '../configs/constants.config';
@Injectable()
export class DataService {
  protected apiURL: string = API_SERVER.protocol + '://' + API_SERVER.ip + API_SERVER.startpoint;
  protected authToken: string;
  currentUserId;

  constructor(
    public http: HttpClient,
    public authService: NbAuthService) {
    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token) {
        this.authToken = token.getValue();
        const tokenPayload = token.getPayload();
        this.currentUserId = tokenPayload.id;
      }
    });
  }

  private getHttpOptions() {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.authToken,
    });
    const options = { headers: headers };
    return options;
  }

    public dataPolices(id: any) {
        return this.http.get(`${this.apiURL}/datapull/users/${this.currentUserId}/showSiniestroAlert/${id}`, this.getHttpOptions());
    }

    public dataAlerts(id: number) {
        // return this.http.get(`${this.apiURL}/datapull/users/${id}/showPolicyAlerts`, this.getHttpOptions())
        return this.http.get(`${this.apiURL}/datapull/datapoliciesV2/user/${id}`, this.getHttpOptions())
    }

    public usersAlert(projectid: number) {
        return this.http.get(`${this.apiURL}/datapull/project/${projectid}/showusers`, this.getHttpOptions())
    }

}