import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LogoService {
  private readonly DEFAULT_LOGO_URL = 'https://server.sinecta.com/assets/images/domains/clientes.png';
  private readonly API_URL = 'https://server.globesensing.com/api/v1/domains';
  private logoCache$: Observable<string>;

  constructor(private http: HttpClient) {}

  getLogo(): Observable<string> {
    if (!this.logoCache$) {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      const options = { headers };

      this.logoCache$ = this.http.get(this.API_URL, options).pipe(
        map((result: any) => {
          const host = window.location.host;
          if (!host) {
            return this.DEFAULT_LOGO_URL;
          }

          const [subdomain] = host.split('.');
          const isLocalhost = subdomain.includes('localhost') || subdomain.includes('159');
          
          if (isLocalhost) {
            return this.DEFAULT_LOGO_URL;
          }

          const domain = result.find(d => d.name === subdomain);
          return domain 
            ? `https://server.sinecta.com/assets/images/domains/${domain.image}`
            : this.DEFAULT_LOGO_URL;
        }),
        shareReplay(1) // Cache the last emitted value and share it with all subscribers
      );
    }

    return this.logoCache$;
  }

  getDefaultLogo(): string {
    return this.DEFAULT_LOGO_URL;
  }

  // Method to clear the cache if needed (e.g., when domain changes)
  clearCache(): void {
    this.logoCache$ = null;
  }
} 