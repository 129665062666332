import { RESTModel } from './rest.model';
import { RESTService } from '../services/rest.service';

export class Action extends RESTModel {
    public action: string;
    public createdAt?: Date;
    public referenceObj?: string;
    public referenceId?: number;

    constructor(service: RESTService<Action>, data: any) {
        super(service, data);
    }
}